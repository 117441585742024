import React from "react";
import styled from "styled-components";

const Download = () => {
  return (
    <Container>
      <Content>
        <TextSection>
          <Tagline>Download Destiny </Tagline>
          <Title>
          Connect <ColoredText>Locally</ColoredText> 
          </Title>
          <Description>
          Destiny connects you with your local community in exciting and meaningful ways. Whether you want to share your story, discover what's happening around you, or collaborate with nearby individuals, Destiny brings your world closer.
          </Description>
          <Divider />
          <FeatureList>
            <Feature>✔ Engage with Nearby Communities</Feature>
            <Feature>✔ Discover Local Events and Happenings</Feature>
            <Feature>✔ Effortless Connections, Real-Time</Feature>
          </FeatureList>
          
          <StoreButtons>
            <StoreButton>
              <img style={{height:40,width:40}}   src="https://cdn-icons-png.flaticon.com/128/300/300218.png" alt="Google Play" />
            </StoreButton>
            <StoreButton>
              <img style={{height:40,width:40}}  src="https://cdn-icons-png.flaticon.com/128/15263/15263166.png" alt="App Store" />
            </StoreButton>
          </StoreButtons>
        </TextSection>
        <ImageSection>
          <img style={{borderRadius:10,objectFit:'contain',height:400}}
            src="https://play-lh.googleusercontent.com/bHPcPGHAC57dFwTouLPygOrNwOZlsRg7PWLyC3Jmb4PKfV0etrhjJTnCRFCD-xaBmYA=w1052-h592-rw"
            alt="Destiny Mockup"
          />
        </ImageSection>
      </Content>
    </Container>
  );
};

export default Download;

const Container = styled.div`
  background-color: #000;
  color: #fff;
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
    min-height:60vh;
`;

const Content = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;



const TextSection = styled.div`
  flex: 1;
`;

const Tagline = styled.p`
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
  color: #bbb;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: 700;
  margin: 0;
  line-height: 1.2;

  @media (min-width: 768px) {
    font-size: 48px;
  }
`;

const ColoredText = styled.span`
  background: linear-gradient(90deg, #ff5a5f, #6e5bff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Description = styled.p`
  font-size: 16px;
  margin: 20px 0;
  line-height: 1.5;
`;

const Divider = styled.hr`
  border: 0;
  height: 1px;
  background: #444;
  margin: 20px 0;
`;

const FeatureList = styled.div`
  font-size: 16px;
  margin: 20px 0;
  line-height: 2;
`;

const Feature = styled.p`
  margin: 0;
`;

const StoreButtons = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`;

const StoreButton = styled.div`
  img {
    width: 150px;
    height: auto;

    @media (min-width: 768px) {
      width: 180px;
    }
  }
`;

const ImageSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const MockupImage = styled.img`
  width: 100%;
  max-width: 300px;
  height: 500px;
  border-radius: 16px;

  @media (max-width: 768px) {
    max-width: 300px;
    
  }
`;
