import React, { useEffect, useRef } from "react";
import styles from "./App.module.css";


import Welcome from "./screens/Welcome";
import { Route, Routes } from "react-router-dom";
import Contact from "./screens/Contact";
import Privacy from "./screens/Privacy";
import PostView from "./screens/PostView";
import Download from "./screens/Download";
import Footer from "./screens/Footer";
import About from "./screens/About";

function App() {

  const contactRef = useRef(null);
  const downloadRef = useRef(null);
  const aboutRef = useRef(null);

  const privacyRef = useRef(null);


  const scrollToContact = () => {
    contactRef.current?.scrollIntoView({ behavior: "smooth" });

  }

  const scrollToAbout = () => {
    aboutRef.current?.scrollIntoView({ behavior: "smooth" });

  }

  const scrollToDownload = () => {
    downloadRef.current?.scrollIntoView({ behavior: "smooth" });
};

const scrollToPrivacy = () => {
  privacyRef.current?.scrollIntoView({ behavior: "smooth" });
};

useEffect(() => {
  // Get the hash from the URL
  const hash = window.location.hash;

  // Check if the hash matches a section and scroll to it
  if (hash === "#contact") {
    scrollToContact();
  } else if (hash === "#about") {
    scrollToAbout();
  } else if (hash === "#download") {
    scrollToDownload();
  }
   else if (hash === "#privacy") {
  scrollToPrivacy();
}
}, []); // 
  return (
    <div className={styles.App}>


      <Welcome scrollToContact={scrollToContact} scrollToDownload={scrollToDownload} scrollToAbout={scrollToAbout} scrollToPrivacy={scrollToPrivacy} />

      <div ref={downloadRef}> 
      <Download />
      </div>

      <div ref={aboutRef}> 
      <About />
      </div>

      <div ref={privacyRef}>
      <Privacy />
      </div>

      <div ref={contactRef}>
      <Contact />
      </div>

   
     

     <Footer/>
    
    </div>
  );
}

export default App;
