import React from "react";
import styled from "styled-components";

const About = () => {
  return (
    <Container>
      <Content>
        <TextSection>
          <Tagline>About Destiny</Tagline>
          <Title>
            Empowering  <ColoredText>Local</ColoredText> Connections.
          </Title>
          <Description>
            Destiny is a localized social networking platform designed to bring 
            people together in their immediate vicinity. Whether you’re looking 
            to share experiences, explore nearby happenings, or connect with 
            like-minded individuals, Destiny is here to redefine the way you 
            interact with your community.
          </Description>
          <FeatureList>
            <Feature>✔ Foster meaningful connections within your local area</Feature>
            <Feature>✔ Stay updated with events and activities around you</Feature>
            <Feature>✔ Share posts, ideas, and stories with nearby users</Feature>
            <Feature>✔ Engage in real-time conversations through group chats</Feature>
            <Feature>✔ Unlock rewards and perks through active participation</Feature>
          </FeatureList>
        </TextSection>
      </Content>
    </Container>
  );
};

export default About;

const Container = styled.div`
  background-color: #000; /* Matches Welcome and Download */
  color: #fff;
  min-height: 60vh;
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const TextSection = styled.div`
  flex: 1;
`;

const Tagline = styled.p`
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: 700;
  margin: 0;
  line-height: 1.2;

  @media (min-width: 768px) {
    font-size: 48px;
  }
`;



const Description = styled.p`
  font-size: 16px;
  margin: 20px 0;
  line-height: 1.5;
`;

const FeatureList = styled.div`
  font-size: 16px;
  margin: 20px 0;
  line-height: 2;
`;

const Feature = styled.p`
  margin: 0;
`;


const ColoredText = styled.span`
  background: linear-gradient(90deg, #ff5a5f, #6e5bff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;