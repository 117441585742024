import React from "react";
import styled from "styled-components";

const Footer = () => {
    return (
        <Container>
            <Content>

                  

                    <img style={{height:40,width:40}} src='https://cdn-icons-png.flaticon.com/128/733/733547.png' alt="facebook" />

            
                    <img style={{height:40,width:40}} src='https://cdn-icons-png.flaticon.com/128/2111/2111463.png' alt="instagram" />

                    <img style={{height:40,width:40}} src='https://cdn-icons-png.flaticon.com/128/3536/3536505.png' alt="linkedin" />



            </Content>
        </Container>
    );
};

export default Footer;

const Container = styled.div`
  background-color: #000;
  color: #fff;
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items:center;
justify-content:center;
gap:20px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const LeftSection = styled.div`
  flex: 1;
 
`;

const Badge = styled.div`
  background: #0056ff;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 8px 16px;
  border-radius: 8px;
  display: inline-block;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 36px;
  line-height: 1.2;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 48px;
  }
`;

