import React from "react";
import styled from "styled-components";

const Container = styled.div`
  font-family: Arial, sans-serif;
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;


  padding: 20px;
`;

const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const Logo = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  flex-direction:row;
  display:flex;
  align-items:center;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 20px;

  a {
    color: #fff;
    text-decoration: none;
    font-size: 20px;

    &:hover {
      color: #f00;
    }

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
`;

const PartnerButton = styled.button`
  background: linear-gradient(45deg, #261ca2, #1c61a2);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 14px;
  }
`;

const Main = styled.main`
  text-align: center;
  margin-top: 50px;

  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

const Heading = styled.h2`
  font-size: 36px;
  line-height: 1.5;

  .highlight {
    background: linear-gradient(45deg, #5d0bc1, #007bff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const Description = styled.p`
  font-size: 18px;
  margin: 20px 0;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;



const Features = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 40px;

  span {
    background: rgba(255, 255, 255, 0.1);
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 16px;
    white-space: nowrap;

    @media (max-width: 768px) {
      font-size: 14px;
      padding: 8px 16px;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 15px;
  }
`;

const Welcome = ({scrollToContact, scrollToDownload,scrollToAbout, scrollToPrivacy}) => {
  return (
    <Container>
      <Header>
        <Logo> <img style={{height:40,width:40}} src={require('../assets/image.png')}  alt="x"/> Destiny</Logo>
        <Nav>
          <a onClick={scrollToAbout} href="#about">About</a>
          <a onClick={scrollToPrivacy} href="#privacy">Privacy</a>
          <a onClick={scrollToContact} href="#contact">Contact</a>
        </Nav>
        <PartnerButton  onClick={scrollToDownload}><a style={{textDecoration:'none',color:'white'}} href="#download">Download App</a></PartnerButton>
      </Header>

      <Main>
        <Heading>
          Connect with Your Local Community
          {/* <span className="highlight"> Instantly, Effortlessly.</span> */}
        </Heading>
        <Description>
          Discover people, events, and happenings around you. Post, chat, and
          engage with your local community.
        </Description>

        <Features>
          <span>📍 Discover Nearby</span>
          <span>👥 Connect with Locals</span>
          <span>🚀 Instant Updates</span>
        </Features>
      </Main>
    </Container>
  );
};

export default Welcome;
