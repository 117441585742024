import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const PostView = () => {

    const { postId } = useParams();
    const [postDetails, setPostDetails] = useState(null)
    const [intailLoad, setIntialLoad] = useState(false)
   
    // const postId ='66e1570d4b718803192a5ebf'
    const userId = '123'
    const ip = '13.127.163.34'

    const getPostDetails = async () => {


        try {
            const res = await axios.post(`http://${ip}:3001/get-post/`, {
                postId: postId,
                userId: userId,
                latLong: {}

            })

            if (res.data.status === 100) {
                setPostDetails(res.data.postDetails)
                setIntialLoad(true)
            }

        }
        catch (e) {
            console.error(e.message)
        }
    }

    useEffect(() => {
     
        getPostDetails()
    }, [])

    if(!postDetails){
        return <div style={styles.container}></div>

    }
  return (

    <div style={styles.container}>
    <div style={styles.postContainer}>
      {/* User Info */}
      <div style={styles.userInfo}>
        <img
          src={postDetails.postedUserProfilePic}
          alt="profile"
          style={styles.profilePic}
        />
        <div style={styles.userDetails}>
          <div style={styles.userName}>{postDetails.postedUserName}</div>
          <div style={styles.postTime}>{postDetails.timeAgo} • {postDetails.awayFromYou} away</div>
        </div>
        <div style={styles.optionsMenu}>
          <i className="fas fa-ellipsis-v"></i>
        </div>
      </div>

      {/* Post Content */}
      <div style={styles.postContent}>
      
        <p>
         {postDetails.postDesc}
        </p>
       
      </div>

      {/* Image Section */}
      <div style={styles.postImage}>
        <img
          src={postDetails?.postImages[0]?.uri} // Replace with actual image URL
          alt="Theka Coffee"
          style={styles.image}
        />
      </div>

      {/* Post Actions */}
      <div style={styles.postActions}>
        <span><i className="fas fa-heart"></i> {postDetails.likes} likes</span>
        <span><i className="fas fa-comment"></i> {postDetails.comments} comment</span>
      </div>
    </div>
    </div>
  );
};

// Inline styles
const styles = {
    container: {
        width: '80vw',
        maxWidth: '800px',
        margin: '0 auto',
        padding: '40px',
        fontFamily: '"Poppins", Arial, sans-serif',
        lineHeight: '1.8',
        // backgroundColor: 'rgba(12, 12, 12, 0.8)',
        borderRadius: '15px',
        // boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
        color: '#fff',
        minHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
        zIndex: 1,
      },
  postContainer: {
    backgroundColor: '#1f1f1f',
    borderRadius: '8px',
    padding: '16px',
    color: '#fff',
    fontFamily: 'Arial, sans-serif',
    maxWidth: '500px',
    margin: '20px auto',
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  profilePic: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    marginRight: '12px',
  },
  userDetails: {
    flexGrow: 1,
  },
  userName: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  postTime: {
    color: '#888',
    fontSize: '12px',
  },
  optionsMenu: {
    color: '#888',
    cursor: 'pointer',
  },
  postContent: {
    margin: '16px 0',
    fontSize:'0.9rem',
    lineHeight:1.5
  },
  hashtags: {
    color: '#00acee',
  },
  postImage: {
    marginTop: '12px',
  },
  image: {
    width: '100%',
    borderRadius: '8px',
    height:500,
  },
  postActions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '12px',
  },
  postActionsItem: {
    color: '#888',
    display: 'flex',
    alignItems: 'center',
  },
};

// Make sure you have FontAwesome for icons
const loadFontAwesome = () => {
  const link = document.createElement('link');
  link.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css';
  link.rel = 'stylesheet';
  document.head.appendChild(link);
};

loadFontAwesome();

export default PostView;
