import React from "react";
import styled from "styled-components";

const Privacy = () => {
  return (
    <Container>
      <Content>
        <TextSection>
          <Tagline>Privacy Policy</Tagline>
          <Title>
            Your <ColoredText>Privacy</ColoredText>, Our Priority
          </Title>
          <Description>
            At Destiny, we take your privacy seriously. Our platform is built 
            to ensure your data is safe, secure, and used responsibly. Here's 
            how we safeguard your experience:
          </Description>
          <FeatureList>
            <Feature>✔ Your location data is only used to fetch nearby posts to keep you informed and engaged with what's happening around you. We do not store or share your location with any third parties.</Feature>
            <Feature>✔ Control over who can view your posts and profile</Feature>
            <Feature>✔ No sharing of personal data with third parties without consent</Feature>
            <Feature>✔ Your phone number is strictly used for login purposes. We do not use your phone number for any other purposes, and it is not shared with any third parties.</Feature>
            <Feature>✔ If you have any questions or concerns about your privacy, please do not hesitate to contact our support team. We are here to ensure your privacy is protected and to provide a safe and secure experience.</Feature>
            <Feature>✔ Our app employs advanced security measures to protect your information from unauthorized access and ensure it is handled with utmost care.</Feature>
          </FeatureList>
          <Description>
            By using Destiny, you’re agreeing to a platform designed to keep 
            your interests at the forefront. Explore, connect, and share with 
            peace of mind.
          </Description>
        </TextSection>
      </Content>
    </Container>
  );
};

export default Privacy;

const Container = styled.div`
  background-color: #000; /* Matches other components */
  color: #fff;
  min-height: 60vh;
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const TextSection = styled.div`
  flex: 1;
`;

const Tagline = styled.p`
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: 700;
  margin: 0;
  line-height: 1.2;

  @media (min-width: 768px) {
    font-size: 48px;
  }
`;

const ColoredText = styled.span`
  background: linear-gradient(90deg, #ff5a5f, #6e5bff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Description = styled.p`
  font-size: 16px;
  margin: 20px 0;
  line-height: 1.5;
`;

const FeatureList = styled.div`
  font-size: 16px;
  margin: 20px 0;
  line-height: 2;
`;

const Feature = styled.p`
  margin: 0;
`;
