import React from "react";
import styled from "styled-components";

const Contact = () => {
  return (
    <Container>
      <Content>
        
        
        <LeftSection>
        <Tagline>Contact Destiny</Tagline>
          <Title>
            Any <ColoredText>Questions ?</ColoredText><br />
            {/* Let's Chat and Make Magic Happen! */}
          </Title>
          <Description>
            Reach out to us—we're here to help you turn your ideas into reality
            and make your content creation process magical!
          </Description>
          <ContactDetails>
           
            <Detail>
              📧 <strong>Email:</strong> support@destinyy.com
            </Detail>
          </ContactDetails>
         
        </LeftSection>
        <RightSection>
          <Form>
            <Input type="text" placeholder="Your name *" />
            <InputWrapper>
              <Input type="tel" placeholder="Phone number *" />
              <Input type="email" placeholder="Email address *" />
            </InputWrapper>
            <Textarea placeholder="Anything else you would like us to know?" />
            <SubmitButton>Get in Touch</SubmitButton>
          </Form>
        </RightSection>
      </Content>
    </Container>
  );
};

export default Contact;

const Container = styled.div`
  background-color: #000;
  color: #fff;
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  align-items: center;

`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 1200px;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const LeftSection = styled.div`
  flex: 1;
 
`;

const Badge = styled.div`
  background: #0056ff;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 8px 16px;
  border-radius: 8px;
  display: inline-block;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 36px;
  line-height: 1.2;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 48px;
  }
`;

const ColoredText = styled.span`
  background: linear-gradient(90deg, #ff5a5f, #6e5bff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
`;

const Tagline = styled.p`
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
`;

const ContactDetails = styled.div`
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 20px;
`;

const Detail = styled.p`
  margin: 0;
`;

const Features = styled.div`
  font-size: 16px;
  line-height: 2;
`;

const Feature = styled.p`
  margin: 0;
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const Form = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Input = styled.input`
  width: 90%;
  padding: 15px;
  font-size: 16px;
  border: 1px solid #444;
  border-radius: 8px;
  background: #111;
  color: #fff;

  &::placeholder {
    color: #aaa;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  input {
    flex: 1;
  }
`;

const Textarea = styled.textarea`
  width: 90%;
  padding: 15px;
  font-size: 16px;
  border: 1px solid #444;
  border-radius: 8px;
  background: #111;
  color: #fff;
  resize: none;
  height: 120px;

  &::placeholder {
    color: #aaa;
  }
`;

const SubmitButton = styled.button`
  padding: 15px 20px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: linear-gradient(45deg, #261ca2, #1c61a2);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  transition: background 0.3s ease;

  &:hover {
    opacity: 0.9;
  }
`;
